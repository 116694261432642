<template>
  <v-container fluid>
    <AppHeader 
      v-if="$store.state.user.isAuthenticated"
      @fopen="openFile"
      @fadd="addFile"
      @save="saveReport"
      @savexlsx="saveToExcel"
      @savepdf="saveToPDF"
      @frontpagepdf="savePDFFrontPage"
      @reportpdf="savePDFReportOnly"
      @savetemplate="saveTemplate()"
      @savenewtemplate="saveNewTemplate=true"
      @viewcustomers="viewCustomers=true"
      @viewreports="viewSavedReports=true"
      @viewtemplates="viewTemplates=true"
      @viewcalendar="viewCalendar=true"
      @viewabbrv="viewAbbreviations=true"
      @bulkreplace="viewBulkReplace=true"
      @addemptyrows="emptyRows=true"
      @manualentry="viewManualEntry=true"
      @applyabbrv="viewToolsAbbrv=true"
      @touppercase="toUppercase()"
      @help="viewHelp=true"
      @help-migration="viewDataMigration=true"
      @help-about="viewAbout=true"
      @help-support="viewSupport=true"
      @myprofile="myProfile=true"
      @mypreferences="myPreferences=true"
    />

    <v-snackbar top right :color="snackbar.color" v-model="snackbar.show" tile>{{snackbar.text}}</v-snackbar>

    <v-row>
      <v-col cols="12">
        <v-progress-linear indeterminate class="ma-2" :active="!!loading"></v-progress-linear>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" sm="12" md="2">
        <v-btn small block tile @click="openFile" color="primary" class="mb-4 bg-gradient"><v-icon small>mdi-file</v-icon> Create New Report</v-btn>
        <v-btn small block tile @click="addFile" color="primary" dark class="my-4 bg-gradient"><v-icon small>mdi-file-plus</v-icon> Add More Data</v-btn>
        <v-btn small block tile @click="saveReport" color="success" class="my-4 success-gradient"><v-icon small>mdi-content-save</v-icon> Save Report</v-btn>
      </v-col>

      <v-col cols="12" sm="12" md="10">
        <v-expansion-panels tile v-model="expPanel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h5 class="primary--text"><v-icon size="17" color="primary">mdi-information-outline</v-icon> REPORT INFORMATION</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="5">
                  <v-autocomplete v-model="report.location" :items="locations" label="Select Customer" dense item-text="text" item-value="id" :rules="[value => !!value || 'Required']" :error-messages="locationErrorMsg">
                    <template v-slot:selection="data">
                      <span>{{data.item.text}}</span>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title v-if="data.item.parent_id==0" style="color:#256aa5;"><strong>{{ data.item.text }}</strong></v-list-item-title>
                        <v-list-item-title v-else style="color:#00bcd4;"><pre>  {{ data.item.text }}</pre></v-list-item-title>
                        <v-list-item-subtitle v-if="data.item?.address">{{ data.item.address }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="data.item?.address2">{{ data.item.address2 }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="data.item?.address3">{{ data.item.address3 }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="data.item?.city"> {{ data.item.city }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="data.item?.zip">{{ data.item.zip }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                  <DatePicker label="Valid To Date" v-model="report.validTo" dense :rules="[value => !!value || 'Required']" :error-messages="validToErrorMsg" />
                </v-col>

                <v-col cols="12" md="2">
                  <v-switch label="Next test booked?" v-model="report.nextTestBooked" inset color="primary"></v-switch>
                </v-col>

                <v-col cols="12" md="2">
                  <v-switch label="Reminders" v-model="report.reminders" inset color="primary"></v-switch>
                </v-col>

                <v-col cols="12">
                  <v-textarea label="Notes" v-model="report.notes" rows="2" dense></v-textarea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row v-if="unsavedChanges">
      <v-col cols="12">
        <v-alert type="warning" dense tile text border="bottom" colored-border elevation="2" icon="mdi-alert-outline" class="caption blue--text">You have unsaved changes. To save them, click File > Save Report.</v-alert>
      </v-col>
    </v-row>
      
    <v-row dense>
      <v-col align="end" class="mb-2 offset-7" cols="12" sm="5">
        <v-btn small tile v-if="showDeleteBtn" @click="deleteRows()"><v-icon small>mdi-delete</v-icon> Delete Rows</v-btn>
      </v-col>
    </v-row>

    <!-- machine info -->
    <v-container fluid class="machine pa-1" v-if="machine.name">
      <v-row dense><v-col cols="12">Device: {{machine.name}}; S/N: {{machine.serial}}</v-col></v-row>
    </v-container>

    <!-- group by container -->
    <v-container fluid>
      <v-chip label close close-icon="mdi-close" color="secondary" small v-for="(field, index) in groupFields" :key="index" class="ml-2" @click="ungroup(field)" @click:close="ungroup(field)">
        <span>{{$store.state.columns.getText(field)}}</span>
      </v-chip>
      <v-chip v-if="groupFields.length > 0" label close close-icon="mdi-close" color="pink" small class="ml-2" @click="ungroupAll()" @click:close="ungroupAll()">
        <span>Remove all</span>
      </v-chip>
    </v-container>

    <!-- Tabulator -->
    <v-container fluid class="tabulator-container" :style="'height:'+windowHeight+'px;'">
      <Tabulator ref="tabulator" :options="options"></Tabulator>
    </v-container>

    <!-- Device Connect RS232 
    <v-dialog v-model="deviceConnectDialog" v-if="deviceConnectDialog">
          <v-card tile>
            <v-toolbar dark dense color="primary" tile :elevation="0">
              <span>Device Connect</span>
              <v-spacer></v-spacer>
              <v-btn icon tile small @click="deviceConnectDialog=false;"><v-icon small>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text v-if="!true">
              The Web Serial API is supported by your browser. Please try with Google Chrome, Microsoft Edge or Opera.
            </v-card-text>
            <v-card-text v-else>
              <v-row>
                <v-col cols="3">
                  <v-select label="Baud Rate" v-model="serialConnection.baud" :items="['9600', '14400', '28800', '57600', '115200']"></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select label="Stop Bits" v-model="serialConnection.stopBits" :items="['1', '2']"></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select label="Parity" v-model="serialConnection.parity" :items="['none', 'odd', 'even']"></v-select>
                </v-col>
                <v-col cols="3">
                  <v-btn>Connect</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
            <v-system-bar lights-out color="white">Status: Disconnected</v-system-bar>
          </v-card>
    </v-dialog>
    -->

    <!-- Insert Empty Rows -->
    <v-dialog v-model="emptyRows" v-if="emptyRows" width="500">
          <v-card tile>
            <v-toolbar dark dense color="primary" tile :elevation="0">
              <span>Insert Empty Rows</span>
              <v-spacer></v-spacer>
              <v-btn icon tile small @click="emptyRows=false;"><v-icon small>mdi-close</v-icon></v-btn>
            </v-toolbar>
            
            <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Number of rows" v-model="rowsNumber"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn color="primary" @click="insertRows()">OK</v-btn>
                  </v-col>
                </v-row>
            </v-card-text>
          </v-card>
    </v-dialog>

    <!-- View My Customers -->
    <v-dialog v-model="viewCustomers" v-if="viewCustomers" fullscreen>
          <v-card tile>
            <v-toolbar dark dense color="primary" tile :elevation="0">
              <span>My Customers</span>
              <v-spacer></v-spacer>
              <v-btn icon tile small @click="viewCustomers=false;"><v-icon small>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <Customers @reloadCustomers="getCustomers()" @customerReports="viewCustomerReports" />
          </v-card>
    </v-dialog>

    <!-- View My Saved Reports -->
    <v-dialog v-model="viewSavedReports" v-if="viewSavedReports" @keydown.esc.prevent="viewSavedReports=false; reportsCustomerProp = 0" fullscreen>
          <v-card tile>
            <v-toolbar dark dense color="primary" tile :elevation="0">
              <span>My Saved Reports</span>
              <v-spacer></v-spacer>
              <v-btn icon tile small @click="viewSavedReports=false; reportsCustomerProp = 0"><v-icon small>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <SavedReports :location_id="reportsCustomerProp" :key="rerenderReports" @editreport="editReport" @mergereports="mergeReports" />
          </v-card>
    </v-dialog>

    <!-- View My Templates -->
    <v-dialog v-model="viewTemplates" v-if="viewTemplates">
          <v-card tile>
            <v-toolbar dark dense color="primary" tile :elevation="0">
              <span>My Templates</span>
              <v-spacer></v-spacer>
              <v-btn icon tile small @click="viewTemplates=false;"><v-icon small>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <Templates :key="rerenderTemplates" @loadtemplate="loadTemplate" />
          </v-card>
    </v-dialog>

    <!-- View My Calendar -->
    <v-dialog v-model="viewCalendar" v-if="viewCalendar" width="800">
          <v-card tile>
            <v-toolbar dark dense tile color="primary" :elevation="0">
              <span>My Calendar</span>
              <v-spacer></v-spacer>
              <v-btn icon tile small @click="viewCalendar=false;"><v-icon small>mdi-close</v-icon></v-btn>
            </v-toolbar>
            <Calendar />
          </v-card>
    </v-dialog>

    <!-- View My Abbreviations -->
    <v-dialog v-model="viewAbbreviations" v-if="viewAbbreviations" width="800">
      <v-card tile>
        <v-toolbar dark dense tile color="primary" :elevation="0">
          <span>My Abbreviations</span>
          <v-spacer></v-spacer>
          <v-btn icon tile small @click="viewAbbreviations=false;"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <Abbreviations />
      </v-card>
    </v-dialog>

    <!-- Tools Find & Replace -->
    <v-dialog v-model="viewBulkReplace" v-if="viewBulkReplace" width="500">
      <v-card tile>
        <v-toolbar dark dense tile color="primary" :elevation="0">
          <span>Find & Replace</span>
          <v-spacer></v-spacer>
          <v-btn icon tile small @click="viewBulkReplace=false; bulkFind=null; bulkReplace=null;"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-row dense>
              <v-col cols="12">
                <v-text-field label="Find What" name="find" v-model="bulkFind"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field label="Replace With" name="replace" v-model="bulkReplace"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <CheckboxList label="Search Only The Selected Columns" name="filter" v-model="bulkFilter" :items="columnFields" itemText="title" itemValue="field"></CheckboxList>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
              <v-btn small tile color="primary" :disabled="!bulkReplace" @click="bulkFindReplace()">Replace All</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

     <!-- Tools Apply Abbreviations -->
     <v-dialog v-model="viewToolsAbbrv" v-if="viewToolsAbbrv" width="500">
      <v-card tile>
        <v-toolbar dark dense tile color="primary" :elevation="0">
          <span>Apply Abbreviations</span>
          <v-spacer></v-spacer>
          <v-btn icon tile small @click="viewToolsAbbrv=false"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-row dense>
              <v-col cols="12">
                <CheckboxList label="Apply Only For The Selected Columns" name="filter" v-model="abbrvFilter" :items="columnTextFields" itemText="title" itemValue="field"></CheckboxList>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn small tile color="primary" @click="applyAbbrv()">Apply</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Tools Manual Entry -->
    <v-dialog v-model="viewManualEntry" v-if="viewManualEntry" width="480">
      <v-card tile>
        <v-toolbar dark dense tile color="primary" :elevation="0">
          <span>Manual Entry</span>
          <v-spacer></v-spacer>
          <v-btn icon tile small @click="viewManualEntry=false; manualEntry = {};"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-6">
          <v-form>
            <v-row dense v-for="(item, index) in columnFields" :key="index">
              <v-col cols="12">
                <v-text-field dense :label="item.title" v-model="manualEntry[item.field]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn tile small color="primary" @click="insertManualEntry()">Insert</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

     <!-- Help -->
    <v-dialog v-model="viewHelp" v-if="viewHelp" width="800">
      <v-card tile>
        <v-toolbar dark dense tile color="primary" :elevation="0">
          <span>Hints</span>
          <v-spacer></v-spacer>
          <v-btn icon tile small @click="viewHelp=false;"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <h1>PAT Connect</h1>
          <p></p>
          <p>PAT Connect is a web based software for processing and manipulation of PAT files and generating a complete report and certificate. It allows merging multiple files into a single report as well.</p>
          <p>Supported file types: <strong>.sss, .tar, .gar, .txt, .csv, .zip, .padfx and .db</strong></p>
          <p><strong>File &gt; Create New Report</strong> &ndash; opens a single file or multiple files (any combination of the supported files is allowed). The files are preprocessed and displayed into the data table creating a new report.</p>
          <p><strong>File &gt; Add More Data</strong> &ndash; opens a single file or multiple files (any combination of the supported files is allowed) and appends the contents to the data table.</p>
          <hr /><br />
          <h3>Data Manipulation</h3>
          <p></p>
          <p><strong>Sorting</strong> &ndash; data in the table can be sorted by clicking on the column name. Clicking again will sort the data in reverse order. Sorting by multiple columns at the same time is possible by holding the CTRL or Shift key when clicking on the column headers.</p>
          <p><strong>Moving columns</strong> &ndash; columns can be reordered by dragging. Click on the column name and hold the left mouse button down while moving the column to the desired location.</p>
          <p><strong>Moving rows</strong> &ndash; rows can be moved by dragging. Click on the row to be moved and hold the left mouse button down while moving the row to the desired location.</p>
          <p><strong>Deleting rows</strong> &ndash; one or several rows can be deleted from the table. Select the rows by checking the checkboxes at the beginning of the row and then click Delete rows button in the top right corner above the table.<br />
          <em>Note: deleting rows cannot be undone unless you open your original PAT files again.</em></p>
          <p><strong>Editing data</strong> &ndash; all cells in the data table are editable. To update the value of any cell, simply click in the cell, change its value, and then click anywhere outside the table. Pressing the <strong>tab</strong> key will automatically move to the next cell in the row. Pressing the <strong>escape</strong> key will cancel the edit operation.</p>
          <p><strong>Grouping data</strong> &ndash; data in the table can be grouped by the values of any column. For example, one can group the rows by the Result column into two separate categories: PASS/FAIL. Grouping is done by double-clicking on a column name. Grouping can be performed on more than one column.</p>
          <p><strong>Ungrouping data</strong> &ndash; data groups can be removed one by one or all at once using the group buttons or the <u>Remove all</u> button in the top left corner of the table.</p>
          <p><strong>Selecting all rows within a group</strong> &ndash; once data is grouped, all rows within a group can be selected by clicking on the group heading title. This can be used in case one wants to delete all rows of a group at once.</p>
          <p><strong>Filter / search</strong> &ndash; in every column header there&rsquo;s an input search box that can be used to filter/search data in the corresponding column that contains the value typed in the box.</p>
          <p><strong>Adding a row manually</strong> &ndash; a new row can be added by using the menu <strong>Tools &gt; Manual Entry</strong>. After providing information for each column the new row is appended at the end of the table.</p>
          <hr /><br />
          <p><strong>File &gt; Save Report</strong> - prior to using this function, a customer should be selected from the dropdown menu above the data table, as well as a valid to date. This function generates a branded PDF certificate containing the customer&rsquo;s details, issuer details and logo, a unique certificate number and all data from the table. The PDF certificate as well as the original PAT file(s) are stored so the user can later view or make any changes. All saved reports can be seen in <strong>View &gt; My Saved Reports</strong> menu.</p>
          <p><strong>File &gt; Export to Excel</strong> &ndash; allows to convert the data table into a Microsoft Excel file.</p>
          <p><strong>File &gt; Preview PDF</strong> &ndash; allows to preview the PDF certificate. Prior to using this function, a customer should be selected from the dropdown menu above the data table, as well as a valid to date. This function generates a branded PDF certificate containing the customer&rsquo;s details, issuer details and logo, a unique certificate number and all data from the table.</p>
          <p><em>Hint: when selecting the valid to date, pressing the &ldquo;<strong>t</strong>&rdquo; key on the keyboard will automatically set today&rsquo;s date and pressing the &ldquo;<strong>n</strong>&rdquo; key on the keyboard will automatically set today&rsquo;s date next year.</em></p>
          <p><strong>File &gt; PDF Front Page</strong> &ndash; allows to print just the PDF certificate. Prior to using this function, a customer should be selected from the dropdown menu above the data table, as well as a valid to date. This function generates a branded PDF certificate containing the customer&rsquo;s details, issuer details and logo and a unique certificate number.</p>
          <p><strong>File &gt; PDF Report Only</strong> &ndash; allows to print just the data in the table to a PDF file.</p>
          <p><strong>Template &gt; My Templates</strong> &ndash; displays your templates for the data table. This allows to select which columns to be visible on the data table and what data to be displayed in the Visual column. One can have many different templates but only one can be set as default. The default template is automatically loaded upon system logon. When no user templates exist, all possible columns are displayed. When nothing is selected for the Visual column, it will only contain PASS/FAIL value.</p>
          <p><strong>Template &gt; Save Current Template</strong> &ndash; this allows to save the currently loaded template after making some changes, e.g., dragging and reordering of columns.</p>
          <p><strong>Template &gt; Save To a New Template</strong> &ndash; allows to create a new template from an existing one after making some changes, e.g., dragging and reordering of columns.</p>
          <p><strong>View &gt; My Customers</strong> &ndash; this is the place to create, view and edit your customers.</p>
          <p><strong>View &gt; My Saved Reports</strong> &ndash; lists all saved reports for the logged user. The user can download the original PAT files, the generated PDF certificate or view/edit the data for the saved report.</p>
          <p><strong>View &gt; My Calendar</strong> &ndash; shows the user&rsquo;s calendar with all their customers and the dates for re-test. Clicking on an event on the calendar allows to view associated PAT report or to remove the event from the calendar.</p>
          <p><strong>View &gt; My Abbreviations</strong> &ndash; this allows to define abbreviations which if found within certain columns of the data table can be easily replaced by the corresponding phrase. Abbreviations can be replaced in the following columns: <strong>Location, Make, Model, Description</strong> and <strong>Notes</strong>.</p>
          <p><strong>Tools &gt; Insert Empty Rows</strong> &ndash; allows to manually add new empty rows to the data table.</p>
          <p><strong>Tools &gt; Manual Entry</strong> &ndash; allows to manually add a new row to the data table.</p>
          <p><strong>Tools &gt; Find & Replace</strong> &ndash; allows to search for a specific string and replace it with another string everywhere in the table (in all rows and columns). To narrow your search, use the checkboxes to select which columns to search.</p>
          <p><strong>Tools &gt; Apply Abbreviations</strong> &ndash; loads all user&rsquo;s saved abbreviations and looks for them in the following columns: <strong>Location, Make, Model, Description</strong> and <strong>Notes</strong>. Then every occurrence of the abbreviations is replaced with its corresponding phrase.</p>
          <p><strong>Tools &gt; To Upper Case</strong> &ndash; Converts all values to upper case in the entire data table.</p>
          <p><strong>Help &gt; Hints</strong> &ndash; shows this page.</p>
          <p><strong>Help &gt; Data Migration</strong> &ndash; Allows to migrate customer data from your existing system to PAT Connect.</p>
          <p><strong>Help &gt; Support</strong> &ndash; allows to contact customer support.</p>
          <p><strong>Help &gt; About</strong> &ndash; shows software version information.</p>

          <hr /><br />
          <h3>Preferences</h3><br />
          <p><strong>Merge Notes</strong> &ndash; when turned on, this allows to merge the notes1, notes2, notes3 and notes4 fields of the PAT device into a single field (Notes) in the software. When turned off, notes1 to notes4 fields from the device can be mapped to any field in the software.</p>
          <p><strong>Map IEC to Earth</strong> &ndash; allows to copy the IEC value into the Earth field.</p>
          <p><strong>Number of Rows in Tables</strong> &ndash; defines how many items (rows) to display in various tables accross the software.</p>
          <p><strong>Date Format</strong> &ndash; allows to convert the date into a preferred format.</p>
          <p><strong>Abbreviations Match</strong> &ndash; determines how the abbreviations are applied. <strong>Single Word:</strong> if the word in a cell matches an abbreviation, it will be replaced with the corresponding phrase. <storng>Whole Cell:</storng> the entire contents of the cell must match an abbreviation to be replaced.</p>
          <p><strong>Certificate Date</strong> &ndash; defines what date to show on the PDF certificate: valid to date or the date the test was performed.</p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Support -->
    <v-dialog v-model="viewSupport" v-if="viewSupport" width="640">
      <v-card tile>
        <v-toolbar dark dense tile color="primary" :elevation="0">
          <span>Support</span>
          <v-spacer></v-spacer>
          <v-btn icon tile small @click="viewSupport=false;"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card class="pa-4">
          <v-radio-group v-model="support" row>
            <v-radio label="I need help with using the software" value="help"></v-radio>
            <v-radio label="I want to report a bug/problem" value="tech"></v-radio>
          </v-radio-group>
          <p v-if="support=='tech'"><v-icon color="warning" small>mdi-alert</v-icon> Please send a video or image explaining the problem</p>
          <v-btn large :href="support == 'help' ? 'https://wa.me/447865456351' : 'https://wa.me/359887673788'" outlined block target="_blank" color="green" :disabled="!support"><v-icon>mdi-whatsapp</v-icon> Chat on WhatsApp</v-btn>
        </v-card>
      </v-card>
    </v-dialog>

     <!-- Data Migration -->
     <v-dialog v-model="viewDataMigration" v-if="viewDataMigration" width="640" @close-data-migration="viewDataMigration=false">
      <v-card tile>
        <v-toolbar dark dense tile color="primary" :elevation="0">
          <span>Data Migration</span>
          <v-spacer></v-spacer>
          <v-btn icon tile small @click="viewDataMigration=false"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <DataMigration />
      </v-card>
     </v-dialog>

    <!--About -->
    <v-dialog v-model="viewAbout" v-if="viewAbout" width="520">
      <v-card tile>
        <v-toolbar dark dense tile color="primary" :elevation="0">
          <span>About</span>
          <v-spacer></v-spacer>
          <v-btn icon tile small @click="viewAbout=false;"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-img src="../assets/dd-logo.png" class="mt-6" title="Digital Developments Ltd."></v-img>
            </v-col>
            <v-col cols="9">
              <v-row class="mt-2">
                <v-col cols="12"><strong>PAT Connect</strong><hr /></v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4">Version:</v-col>
                <v-col cols="6">{{software.version}}</v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">Released:</v-col>
                <v-col cols="6">{{software.date}} ({{ parseInt((Date.now() - new Date(software.date).getTime())/(24*3600*1000)) }}  days ago)</v-col>
              </v-row>

              <v-row dense>
                <v-col cols="4">Copyright©</v-col>
                <v-col cols="6"><a href="https://digitaldevelopments.tech" target="_blank" class="text-decoration-none" rel="noopener">Digital Developments Ltd.</a></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- My Profile -->
    <v-dialog v-model="myProfile" v-if="myProfile" width="800">
      <v-card tile>
        <v-toolbar dark dense tile color="primary" :elevation="0">
          <span>My Profile</span>
          <v-spacer></v-spacer>
          <v-btn icon tile small @click="myProfile=false;"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <Profile />
      </v-card>
    </v-dialog>

    <!-- My Preferences -->
    <v-dialog v-model="myPreferences" v-if="myPreferences" width="680">
      <v-card tile>
        <v-toolbar dark dense tile color="primary" :elevation="0">
          <span>My Preferences</span>
          <v-spacer></v-spacer>
          <v-btn icon tile small @click="myPreferences=false;"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <Preferences />
      </v-card>
    </v-dialog>

    <!-- Save New Template -->
    <v-dialog v-model="saveNewTemplate" v-if="saveNewTemplate" width="500">
      <v-card tile>
        <v-toolbar dark dense tile color="primary" :elevation="0">
          <span>New Template</span>
          <v-spacer></v-spacer>
          <v-btn icon tile small @click="saveNewTemplate=false;"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="formValid">
            <v-row dense>
              <v-col cols="12">
                <v-text-field label="Template Name" v-model="newTemplateName" :rules="[value => !!value || 'Required']"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch v-model="templateMakeDefault" label="Make Default"></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col align="end" cols="12">
              <v-btn color="primary" tile small @click="SaveNewTemplate()" :disabled="!formValid">Save</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Tabulator from '../components/Tabulator.vue';
  import Customers from "../views/Customers.vue";
  import SavedReports from "../views/Reports.vue";
  import Templates from "../views/Templates.vue";
  import Profile from "../views/Profile.vue";
  import Preferences from "../views/Preferences.vue";
  import Calendar from "../views/Calendar.vue";
  import Abbreviations from "../views/Abbreviations.vue";
  import AppHeader from "../components/AppHeader.vue";
  import DatePicker from "../components/DatePickerInput.vue";
  import CheckboxList from "../components/CheckboxList.vue";
  import DataMigration from "../views/DataMigration.vue";
  import XLSX from "xlsx";
  import moment from 'moment';
  import 'moment/locale/en-gb';

  import 'tabulator-tables/dist/css/tabulator_materialize.min.css'

  export default {
    components: {
      Tabulator,
      AppHeader,
      Customers,
      SavedReports,
      Templates,
      Abbreviations,
      Calendar,
      Profile,
      Preferences,
      DatePicker,
      CheckboxList,
      DataMigration
    },
    data() {
      return {
        software: {
          version: "1.9.0",
          date: "2024-12-02",
        },
        tabulator: null,
        tableBuilt: false,
        machine: {name: "", serial: ""},
        options: {
          columns: [],
          data: [],
          movableColumns: true,
          movableRows: true,
          layout:"fitColumns",
          responsiveLayout:true,
          selectable:"highlight",
          rowFormatter: this.rowFormatter,
          groupUpdateOnCellEdit:true,
          groupToggleElement: "arrow",
          reactiveData:true,
          debugInvalidComponentFuncs:false,
          height: "800px",
        },
        columns: [],
        allColumns: [
          {formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center", headerSort:false, width:5},
          {title: "No", field: "id", sorter: "alphanum", editor:true, headerVertical:false, hozAlign:"center", headerFilter:"input"},
          {title: "Date", field: "date", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", headerDblClick: this.group},
          {title: "Time", field: "time", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", headerDblClick: this.group},
          {title: "Venue", field: "venue", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", headerDblClick: this.group},
          {title: "Location", field: "location", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", headerDblClick: this.group, formatter:"textarea"},
          {title: "Tester", field: "user", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", headerDblClick: this.group},
          {title: "Description", field: "description", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", headerDblClick: this.group, formatter:"textarea"},
          {title: "Make", field: "make", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", headerDblClick: this.group, formatter:"textarea"},
          {title: "Model", field: "model", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", headerDblClick: this.group, formatter:"textarea"},
          {title: "Notes", field: "notes", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", headerDblClick: this.group, formatter:"textarea"},
          {title: "Visual", field: "visual", sorter: "string", editor:true, headerVertical:false, hozAlign:"center", headerFilter:"input", headerDblClick: this.group, formatter:"textarea"},
          {title: "IEC, Ω", field: "iec", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", hozAlign:"center", headerDblClick: this.group, formatter:"textarea"},
          {title: "EARTH, Ω", field: "earth", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", hozAlign:"center", headerDblClick: this.group, formatter:"textarea"},
          {title: "INS, MΩ", field: "ins", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", hozAlign:"center", headerDblClick: this.group, formatter:"textarea"},
          {title: "LKGE, mA", field: "lkge", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", hozAlign:"center", headerDblClick: this.group, formatter:"textarea"},
          {title: "SUB LKGE, mA", field: "sublkge", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", hozAlign:"center", headerDblClick: this.group, formatter:"textarea"},
          {title: "MW LKGE, mW/cm²", field: "mw", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", hozAlign:"center", headerDblClick: this.group, formatter:"textarea"},
          {title: "MAINS, V", field: "mains", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", hozAlign:"center", headerDblClick: this.group, formatter:"textarea"},
          {title: "POLARITY", field: "polarity", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", hozAlign:"center", headerDblClick: this.group, formatter:"textarea"},
          {title: "RCD, ms", field: "rcd", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", hozAlign:"center", headerDblClick: this.group, formatter:"textarea"},
          {title: "P2P, Ω", field: "p2p", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", hozAlign:"center", headerDblClick: this.group, formatter:"textarea"},
          {title: "Retest Period", field: "retest", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", hozAlign:"center", headerDblClick: this.group},
          {title: "Visual Retest Period", field: "visual_retest", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", hozAlign:"center", headerDblClick: this.group},
          {title: "Result", field: "result", sorter: "string", editor:true, headerVertical:false, headerFilter:"input", headerDblClick: this.group},
        ],
        groupFields: [],
        loading: false,
        rowsNumber: null,
        manualEntry: {},
        inputFiles: [],
        locations: [],
        locationErrorMsg: [],
        validToErrorMsg: [],
        report: {
          id: 0,
          location: null,
          validTo: null,
          nextTestBooked: 0,
          reminders: 0,
          notes: null,
          created: null,
        },
        append: false,
        snackbar: {
          show: false,
          text: "",
          color: null,
        },
        expPanel: 0,
        windowHeight: window.innerHeight-200,
        /*
        serialConnection: {
          baud: '28800',
          stopBits: '1',
          parity: 'none',
          dataBits: '8'
        },
        */
        /* dialogs */
        //deviceConnectDialog: false,
        emptyRows: false,
        viewCustomers: false,
        viewSavedReports: false,
        viewTemplates: false,
        viewCalendar: false,
        viewAbbreviations: false,
        viewBulkReplace: false,
        viewToolsAbbrv: false,
        viewManualEntry: false,
        myProfile: false,
        myPreferences: false,
        bulkFind: null,
        bulkReplace: null,
        bulkFilter: "[]",
        abbrvFilter: "[]",
        viewHelp: null,
        viewAbout: null,
        viewSupport: null,
        viewDataMigration: null,
        saveNewTemplate: false,
        newTemplateName: null,
        templateMakeDefault: false,
        templateMenu: false,
        currentTemplateId: null,
        rerenderTemplates: 0,
        rerenderReports: 0,
        formValid: true,
        reportsCustomerProp: 0,
        unsavedChanges: false,
        viz: [],
        support: null,
      }
    },
    methods: {
      initialized() {
        this.tableBuilt = true;
      },
      rowFormatter(row) {
        let data = row.getData();
       
        if(data.result?.length > 0) {
          if(data.result?.toUpperCase() == "FAIL") {
            let theRow = row.getElement();
            //theRow.style.backgroundColor = "#f8d7da";
            theRow.style.borderColor = "#cd3838";
            theRow.style.color = "#cd3838";
          }
        }
      },
      cellEdited(cell) {
        this.rowFormatter(cell.getRow());
        this.unsavedChanges = true;
      },
      // eslint-disable-next-line no-unused-vars
      groupClick(e, g) {
        g.getRows().forEach(row => {
          row.toggleSelect();
        });
      },
      group(event, header) {
        if(event.type == "dblclick") {
          
          if(this.groupFields.indexOf(header.getField()) == -1) {
            this.groupFields.push(header.getField());
            this.tabulator.setGroupBy(this.groupFields);
          }
        }
      },
      ungroup(field) {
        this.groupFields.splice(this.groupFields.indexOf(field),1);
        this.tabulator.setGroupBy(this.groupFields);

        if(this.groupFields.length == 0) {
          this.addRowCount();
          this.tabulator.setColumns(this.columns);
        }
      },
      ungroupAll() {
        this.groupFields = [];
        
        this.tabulator.setGroupBy(this.groupFields);
        this.addRowCount();
        this.tabulator.setColumns(this.columns);
      },
      addFile() {
        this.append = true;

        let input = null;
        input = document.createElement("input");
        input.type = "file";
        input.accept = ".sss, .txt, .tar, .gar, .csv, .zip, .padfx, .db";
        input.multiple = true;
        input.onchange = this.readFile;
        input.click();
      },
      openFile() {
        this.append = false;
        this.inputFiles = [];

        let input = null;
        input = document.createElement("input");
        input.type = "file";
        input.accept = ".sss, .txt, .tar, .gar, .csv, .zip, .padfx, .db";
        input.multiple = true;
        input.onchange = this.readFile;
        input.click();
      },
      readFile(event) {
        let files = event.target.files;
        let promises = [];
        let errors = [];
        let app = this;
        
        this.machine = {name: "", serial: ""};

        this.loading = true;

        for(let i=0; i<files.length; i++) {
					let file = files[i];

					let formData = new FormData();
          formData.append('client_id', app.$store.state.user.payload.id);
          formData.append('file', file);
						
					promises.push(app.axios.post(app.ApiEndpoint+"parser.php", formData));

          if(promises.length == files.length) {
            Promise.all(promises).then((values) => {
              let output = [];
              for(let j=0; j<values.length; j++) {
                if(values[j].data.status == "success") {
                  output = output.concat(values[j].data.data);
                  app.inputFiles.push(values[j].data.file);
                }
                else {
                  errors.push(values[j].data.message);
                }
              }
              if(output.length > 0) {
                app.setData(app.fitData(output));
                app.expPanel = 0;
              }
            }).finally(() => {
              app.loading = false;
              if(errors.length) {
                app.snackbar.show = true;
                app.snackbar.text = errors.join(" \r\n");
                app.snackbar.color = app.$vuetify.theme.themes.light.error;
              }
            });
          }
				}
      },
      fitData(json) {
				let data = [];
        let num = 1;
				
				for(let i=0; i<json.length; i++) {
					if(json[i]['type'] == 'MACHINE') {
            this.machine['name'] = json[i]['machine'];
            this.machine['serial'] = json[i]['serial'];
          }
					else {
            let row = {};
            
            let parsedDate = null;

            if(this.$store.state.user.preferences?.date_format) {
              moment.locale('en-gb');
              parsedDate = moment(json[i]['date'], ["DD/MM/YYYY", "DD-MM-YYYY", "YYYY-MM-DD", "LL"]).format("YYYY-MM-DD");

              if(this.$store.state.user.preferences.date_format == 'iso') {
                row['date'] = new Date(parsedDate).toISOString().substring(0,10);
              }
              else {
                row['date'] = new Date(parsedDate).toLocaleDateString(this.$store.state.user.preferences.date_format);
              }
            }
            else {
              row['date'] = json[i]['date'];
            }
            
            row['time'] = json[i]['time'];
            row['num'] = num;
            row['id'] = json[i]['id'];
            row['venue'] = json[i]['venue'];
            row['location'] = json[i]['location'];
            row['user'] = json[i]['user'];
            row['description'] = json[i]['description'];
            row['make'] = json[i]['make'];
            row['model'] = json[i]['model'];
            row['result'] = json[i]['result'];
            row['retest'] = json[i]['full_retest'];
            row['visual_retest'] = json[i]['visual_retest'];

            if(this.$store.state.user.preferences && this.$store.state.user.preferences.merge_notes) {
              row['notes'] = String((json[i]['notes1'] ? json[i]['notes1'] + "\n" : "")  + (json[i]['notes2'] ? json[i]['notes2'] + "\n" : "") + (json[i]['notes3'] ? json[i]['notes3'] : "") + (json[i]['notes4'] ? json[i]['notes4'] : "")).trim();
            }
            else {
              let k1 = this.$store.state.user.preferences?.notes1 ? this.$store.state.user.preferences.notes1 : 'description';
              let k2 = this.$store.state.user.preferences?.notes2 ? this.$store.state.user.preferences.notes2 : 'make';
              let k3 = this.$store.state.user.preferences?.notes3 ? this.$store.state.user.preferences.notes3 : 'model';
              let k4 = this.$store.state.user.preferences?.notes4 ? this.$store.state.user.preferences.notes4 : 'notes';

              let v1 = json[i]['notes1'] ? json[i]['notes1'] : "";
              let v2 = json[i]['notes2'] ? json[i]['notes2'] : "";
              let v3 = json[i]['notes3'] ? json[i]['notes3'] : "";
              let v4 = json[i]['notes4'] ? json[i]['notes4'] : "";
              
              row[k1] = row[k1] ? row[k1] + "\n" + v1 : v1;
              row[k2] = row[k2] ? row[k2] + "\n" + v2 : v2;
              row[k3] = row[k3] ? row[k3] + "\n" + v3 : v3;
              row[k4] = row[k4] ? row[k4] + "\n" + v4 : v4;
            }
            
            if(json[i]['data']?.length > 0) {
              for(let j=0; j<json[i]['data'].length; j++) {
                if(json[i]['data'][j]['type'] == 'VISUAL') {
                  if(this.viz?.length > 0) {
                    let viz_name = json[i]['data'][j]['name']?.trim()?.toLowerCase()?.replaceAll("  ", " ")?.replaceAll("\n", " ");
                    
                    if(this.viz.indexOf(viz_name) != -1) {
                      row['visual'] = (row['visual'] || "") + json[i]['data'][j]['name']+": "+ ((json[i]['data'][j]['value'] != "0.00" && !isNaN(parseFloat(json[i]['data'][j]['value']?.replaceAll(",", "")))) ? parseFloat(json[i]['data'][j]['value']?.replaceAll(",", ""))?.toFixed(0)  + json[i]['data'][j]['units'] : json[i]['data'][j]['result']) + "\n";
                    }
                  }
                  else {
                    row['visual'] = String(json[i]['data'][j]['result']).toUpperCase();
                  }
                }
                else if(json[i]['data'][j]['type'] == 'EARTH') {
                  row['earth'] = row['earth'] ? row['earth'] + "\n" + json[i]['data'][j]['value'] : json[i]['data'][j]['value'];
                }
                else if(json[i]['data'][j]['type'] == 'INSULATION') {
                  row['ins'] = row['ins'] ? row['ins'] + "\n" + json[i]['data'][j]['value'] : json[i]['data'][j]['value'];
                }
                else if(json[i]['data'][j]['type'] == 'LEAKAGE') {
                  row['lkge'] = row['lkge'] ? row['lkge'] + "\n" + json[i]['data'][j]['value'] : json[i]['data'][j]['value'];
                }
                else if(json[i]['data'][j]['type'] == 'SUB LEAKAGE') {
                  row['sublkge'] = row['sublkge'] ? row['sublkge'] + "\n" + json[i]['data'][j]['value'] : json[i]['data'][j]['value'];
                }
                else if(json[i]['data'][j]['type'] == 'MW LEAKAGE') {
                  row['mw'] = row['mw'] ? row['mw'] + "\n" + json[i]['data'][j]['value'] : json[i]['data'][j]['value'];
                }
                else if(json[i]['data'][j]['type'] == 'IEC') {
                  row['iec'] = row['iec'] ? row['iec'] + "\n" + json[i]['data'][j]['value'] : json[i]['data'][j]['value'];
                  if(this.$store.state.user.preferences?.map_iec) {
                    row['earth'] = row['earth'] ? row['earth'] + "\n" + json[i]['data'][j]['value'] : json[i]['data'][j]['value'];
                  }
                }
                else if(json[i]['data'][j]['type'] == 'RCD') {
                  row['rcd'] = row['rcd'] ? row['rcd'] + "\n" + json[i]['data'][j]['value'] : json[i]['data'][j]['value'];
                }
                else if(json[i]['data'][j]['type'] == 'MAINS VOLTAGE') {
                  row['mains'] = row['mains'] ? row['mains'] + "\n" + json[i]['data'][j]['value'] : json[i]['data'][j]['value'];
                }
                else if(json[i]['data'][j]['type'] == 'POLARITY') {
                  row['polarity'] = row['polarity'] ? row['polarity'] + "\n" + json[i]['data'][j]['value'] : json[i]['data'][j]['value'];
                }
                else if(json[i]['data'][j]['type'] == 'P2P') {
                  row['p2p'] = row['p2p'] ? row['p2p'] + "\n" + json[i]['data'][j]['value'] : json[i]['data'][j]['value'];
                }
              }

              if(!row['visual']) row['visual'] = row['result'];
            }
            
            data.push(row);
            num++;
          }
				}
				
				return data;
			},
      setData(data) {
        if(this.append) {
          // Get data from tabulator!!
          let tabulatorData = this.tabulator.getData();
          tabulatorData = tabulatorData.concat(data);
          this.tabulator.setData(tabulatorData);
        }
        else {
          this.report.id = null;
          this.report.location = null;
          this.report.validTo = null;
          this.report.notes = null;
          this.report.nextTestBooked = 0;
          this.report.reminders = 0;

          this.tabulator.setData(data);
        }
        this.unsavedChanges = true;
      },
      deleteRows() {
        let rows = this.tabulator.getSelectedRows();

        if(!(rows?.length > 0)) {
          alert("Please make a selection first.");
          this.loading = false;
          return;
        }

        
        if(confirm("Are you sure you want to delete selected row(s)?")) {
          this.tabulator.deleteRow(rows);
          this.unsavedChanges = true;
        }
      },
      viewCustomerReports(customer_id) {
        this.viewCustomers = false;
        this.reportsCustomerProp = customer_id;
        this.viewSavedReports = true;
      },
      editReport(obj) {
        this.viewSavedReports = false;
        
        var headers = JSON.parse(obj.headers);

        if(headers?.length > 0) {
          this.loading = true;

          this.columns = [];
          this.columns.push({formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center", headerSort:false, width:5});

          for(let index in headers) {
            for(const item in this.allColumns) {
              if(this.allColumns[item].field == headers[index]) {
                this.columns.push(this.allColumns[item]);
              }
            }
          }

          // get report data
          let app = this;
          this.axios.get(this.ApiEndpoint + "loadreport.php", { params: {'client_id': this.$store.state.user.payload.id, 'report_id': obj.id}}).then(res => {
            if(res.data?.length > 0) {
              // set columns
              app.tabulator.setColumns(this.columns);
              // set report details
              app.report.id = obj.id;
              app.report.location = obj.location_id;
              app.report.validTo = obj.valid_to;
              app.report.nextTestBooked = parseInt(obj.next_test_booked);
              app.report.reminders = parseInt(obj.reminders);
              app.report.notes = obj.notes;
              app.report.created = obj.created;
              // set data
              app.tabulator.setData(res.data);
              app.unsavedChanges = false;
            }
          }).finally(() => {
            app.loading = false;
          });
        }
      },
      mergeReports(arr) {
        let app = this;

        app.loading = true;
        this.viewSavedReports = false;
        let headers = []
        for(let i=0; i<arr.length; i++) {
          let h = JSON.parse(arr[i].headers) || []
          headers.push(...h)
        }
        headers = [...new Set(headers)]

        this.columns = [];
        this.columns.push({formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center", headerSort:false, width:5});

        for(let index in headers) {
          for(const item in this.allColumns) {
            if(this.allColumns[item].field == headers[index]) {
              this.columns.push(this.allColumns[item]);
            }
          }
        }

        let promises = [];
        for(let i=0; i<arr.length; i++) {
          promises.push(this.axios.get(this.ApiEndpoint + "loadreport.php", { params: {'client_id': this.$store.state.user.payload.id, 'report_id': arr[i].id}}))
        }

        let mergedData = [];
        Promise.all(promises).then(args => {
          for(let i=0; i<args.length; i++) {
            mergedData.push(...args[i].data)
          }
          app.report.id = 0;
          app.report.location = null;
          app.report.validTo = null;
          app.report.nextTestBooked = 0;
          app.report.reminders = 0;
          app.report.notes = null;
          app.report.created = null;
          // set data
          app.tabulator.setColumns(this.columns);
          app.tabulator.setData(mergedData);
          app.loading = false;
        })
      },
      saveReport() {
        this.loading = true;
        let app = this;
        
        let data = this.tabulator.getData();
        let headers = [];

        if(!this.report.location) {
          this.locationErrorMsg.push("Required");
          this.loading = false;
          return;
        }
        if(!this.report.validTo) {
          this.validToErrorMsg.push("Required");
          this.loading = false;
          return;
        }
        /*
        if(data.length == 0) {
          this.snackbar.text = "No file has been opened!";
          this.snackbar.color = this.$vuetify.theme.themes.light.error;
          this.snackbar.show = true;
          this.loading = false;
          return;
        }
        */
        

				let cols = this.tabulator.getColumns();
				for(let i=0;i < cols.length; i++) {
					if(cols[i].getField()) headers.push(cols[i].getField());
				}

        this.axios.post(this.ApiEndpoint+"save.php", {
          "report_id": this.report.id,
          "client_id": this.$store.state.user.payload.id,
          "location_id": this.report.location,
          "inputFiles": this.inputFiles,
          "validTo": this.report.validTo,
          "notes": this.report.notes,
          "created": this.report.created,
          "nextTestBooked": this.report.nextTestBooked,
          "reminders": this.report.reminders,
          "headers": headers,
          "data": data,
        }).then(res => {
          if(res.data.status == "OK") {
            app.report.id = res.data.report_id;
            app.rerenderReports++;
            app.snackbar.show = true;
            app.snackbar.text = "Report saved."
            app.snackbar.color = "green";
            app.inputFiles = [];
            app.unsavedChanges = false;
          }
          else {
            app.snackbar.show = true;
            app.snackbar.text = res.data.status;
            app.snackbar.color = app.$vuetify.theme.themes.light.error;
          }
        }).finally(() => {
          app.loading = false;
        });
      },
      saveToExcel() {
        let data = this.tabulator.getData("active");

        if(data.length == 0) {
          this.snackbar.text = "No file has been opened!"
          this.snackbar.color = this.$vuetify.theme.themes.light.error;
          this.snackbar.show = true;
          return;
        }

        window.XLSX = XLSX;
        this.tabulator.download("xlsx", "report.xlsx", {sheetName: "PAT Report"});
      },
      savePDFFrontPage() {
        let app = this;
        this.loading = true;

        if(!this.report.location) {
          this.locationErrorMsg.push("Required");
          this.loading = false;
          return;
        }
        if(!this.report.validTo) {
          this.validToErrorMsg.push("Required");
          this.loading = false;
          return;
        }

        this.axios.post(this.ApiEndpoint+"pdf.php", {"client_id": this.$store.state.user.payload.id, "location_id": this.report.location, "validTo": this.report.validTo, "frontPageOnly": true}, {responseType: 'blob'}).then(responseBlob => {
          let url = window.URL.createObjectURL(responseBlob.data);
          let a = document.createElement("a");
          a.download = "PAT_Certificate_Front_Page.pdf";
          a.href = url;
          a.dataset.downloadurl = ["application/pdf", a.download, a.href].join(":");
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          app.loading = false;
        }).catch(() => {
          app.loading = false;
        });
      },
      saveToPDF() {
        let app = this;
        this.loading = true;

        if(!this.report.location) {
          this.locationErrorMsg.push("Required");
          this.loading = false;
          return;
        }
        if(!this.report.validTo) {
          this.validToErrorMsg.push("Required");
          this.loading = false;
          return;
        }

        let data = this.tabulator.getData("active");

        if(data.length == 0) {
          this.snackbar.text = "No file has been opened!"
          this.snackbar.color = this.$vuetify.theme.themes.light.error;
          this.snackbar.show = true;
          this.loading = false;
          return;
        }

        let headers = [];
				let cols = this.tabulator.getColumns();
				for(let i=0;i < cols.length; i++) {
					if(cols[i].getField()) headers.push(cols[i].getField());
				}

        this.axios.post(this.ApiEndpoint+"pdf.php", {"client_id": this.$store.state.user.payload.id, "location_id": this.report.location, "validTo": this.report.validTo, "notes": this.report.notes, "created": this.report.created, "headers": headers, "data": data,}, {responseType: 'blob'}).then(responseBlob => {
          let url = window.URL.createObjectURL(responseBlob.data);
          let a = document.createElement("a");
          a.download = "PAT_Testing_Report.pdf";
          a.href = url;
          a.dataset.downloadurl = ["application/pdf", a.download, a.href].join(":");
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          app.loading = false;
        }).catch(() => {
          app.loading = false;
        });
      },
      savePDFReportOnly() {
        let app = this;
        this.loading = true;
        let data = this.tabulator.getData("active");

        if(data.length == 0) {
          this.snackbar.text = "No file has been opened!"
          this.snackbar.color = this.$vuetify.theme.themes.light.error;
          this.snackbar.show = true;
          this.loading = false;
          return;
        }

        let headers = [];
				let cols = this.tabulator.getColumns();
				for(let i=0;i < cols.length; i++) {
					if(cols[i].getField()) headers.push(cols[i].getField());
				}

        this.axios.post(this.ApiEndpoint+"pdf.php", {"notes": this.report.notes, "headers": headers, "data": data, "reportOnly": true}, {responseType: 'blob'}).then(responseBlob => {
          let url = window.URL.createObjectURL(responseBlob.data);
          let a = document.createElement("a");
          a.download = "PAT_Testing_Report.pdf";
          a.href = url;
          a.dataset.downloadurl = ["application/pdf", a.download, a.href].join(":");
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          app.loading = false;
        }).catch(() => {
          app.loading = false;
        });
      },
      bulkFindReplace() {
        let data = this.tabulator.getData();

         if(data.length < 1) {
          this.snackbar.text = "No file has been opened!"
          this.snackbar.color = this.$vuetify.theme.themes.light.error;
          this.snackbar.show = true;
          return;
        }
        
        let app = this;
        let filter = JSON.parse(this.bulkFilter);

        this.loading = true;
        data.forEach(element => {
          for(const prop in element) {
            if((filter.length > 0 && filter.indexOf(prop) != -1) || (filter.length == 0)) {
              if(String(element[prop] || "").toLowerCase().indexOf(String(app.bulkFind || "").toLowerCase()) != -1) {
                if(String(element[prop] || "").length == 0) {
                  element[prop] = app.bulkReplace;
                }
                else {
                  let regex = new RegExp(app.bulkFind, 'gi');
                  element[prop] = String(element[prop]).replaceAll(regex, app.bulkReplace);
                }
                app.unsavedChanges = true;
              }
            }
          }
        });

        this.tabulator.replaceData(data).then(() => {
          this.bulkFind = null;
          this.bulkReplace = null;
          this.bulkFilter = JSON.stringify([]);
          this.loading = false;
          this.viewBulkReplace = false;
        });
      },
      insertManualEntry() {
        this.tabulator.addData([this.manualEntry]).then(() => this.unsavedChanges = true);
        this.manualEntry = {};
        this.viewManualEntry = false;
      },
      insertRows() {
        let rows = [];
        let entry = {};
        this.columnFields.forEach((item) => {
          entry[item.field] = "";
        });
        for(let i=0; i<this.rowsNumber; i++) {
          rows.push(entry);
        }

        this.emptyRows = false;
        this.tabulator.addData(rows).then(() => this.unsavedChanges = true);
      },
      applyAbbrv() {
        let data = this.tabulator.getData();

        if(data.length < 1) {
          this.snackbar.text = "No file has been opened!"
          this.snackbar.color = this.$vuetify.theme.themes.light.error;
          this.snackbar.show = true;
          return;
        }

        let abbreviations = [];
        let phrases = [];
        let filter = JSON.parse(this.abbrvFilter);

        this.loading = true;
        let app = this;
        this.axios.post(this.ApiEndpoint, {
          table: "abbreviations",
          operation: "select",
          fields: ["abbrv", "phrase"],
          where: [{client_id:{"eq": this.$store.state.user.payload.id}}, {active: {"eq": "1"}}, {deleted: {"eq":"0"}}]
        }).then(res => {
          if(res.data.data.length > 0) {
            for(let i=0; i<res.data.data.length; i++) {
              abbreviations.push(res.data.data[i].abbrv);
              phrases[res.data.data[i].abbrv] = res.data.data[i].phrase;
            }

            if(abbreviations.length > 0) {
              data.forEach((element, index, data) => {
                for(const key in element) {
                  if(String(element[key]).length > 0 && ['venue', 'location', 'make', 'model', 'description', 'notes', 'result', 'user', 'visual'].indexOf(key) != -1) {
                    if((filter.length > 0 && filter.indexOf(key) != -1) || filter.length == 0)  {
                      if(app.$store.state.user.preferences?.abbrv == "cell" || !app.$store.state.user.preferences?.abbrv) {
                        let cellText = String(element[key]).replace(/^\s+|\s+$/g, '');
                      
                        if(abbreviations.indexOf(cellText) != -1) {
                          data[index][key] = String(data[index][key]).replaceAll(cellText, phrases[cellText]);
                          app.unsavedChanges = true;
                        }
                      }
                      else {
                        let words = String(element[key]).split(/\s+/)?.map((str) => str.replace(/^\s+|\s+$/g, ''));

                        for(let i=0; i<abbreviations.length; i++) {
                          while(words.indexOf(abbreviations[i]) != -1) {
                            words[words.indexOf(abbreviations[i])] = phrases[abbreviations[i]]
                          }
                        }
                        data[index][key] = words.join(" ");
                      }
                    }
                  }
                }
              });
            }
          }
        }).finally(() => {
          app.tabulator.setData(data);
          app.loading = false;
          app.viewToolsAbbrv = false;
        });
      },
      toUppercase() {
        let data = this.tabulator.getData();

        if(data.length < 1) {
          this.snackbar.text = "No file has been opened!"
          this.snackbar.color = this.$vuetify.theme.themes.light.error;
          this.snackbar.show = true;
          return;
        }

        data.forEach(element => {
          for(const prop in element) {
              if(element[prop] != undefined) element[prop] = String(element[prop]).toUpperCase();
          }
        });

        this.tabulator.setData(data);
        this.unsavedChanges = true;
      },
      addRowCount() {
        // remove current row count
        for(let i=0; i<this.columns.length; i++) {
          this.columns[i].bottomCalc = null;
          this.columns[i].bottomCalcFormatter = function() {return null;}
        }

        // insert row count on the last column
        this.columns[this.columns.length-1].bottomCalc = function(values) {
          let total = 0;
          values.forEach(() => {
            total++;
          });
          return total;
        };
        this.columns[this.columns.length-1].bottomCalcFormatter = function(cell) {
          return "Total: "+ cell.getValue();
        }
      },
      columnMoved(column, columns) {
        let newOrder = [];
        for(let i=0; i<columns.length; i++) {
          let colObj = columns[i].getDefinition();
          colObj.bottomCalc = null;
          colObj.bottomCalcFormatter = function() {return null;}
          newOrder.push(colObj);
        }
        
        this.columns = newOrder;
        this.addRowCount();
        
        
        this.tabulator.setColumns(this.columns);
      },
      loadTemplate(template_id, template_columns, visual_fields) {
        let cols = JSON.parse(template_columns);
        this.viz = JSON.parse(visual_fields);

        this.currentTemplateId = template_id;

        if(cols.length > 0) {
          this.columns = [];
          this.columns.push({formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center", headerSort:false, width:5});

          for(const col in cols) {
            for(const item in this.allColumns) {
              if(this.allColumns[item].field == cols[col]) this.columns.push(this.allColumns[item]);
            }
          }
        }
        this.addRowCount();

        
        this.tabulator.setColumns(this.columns);
        this.viewTemplates = false;
        this.snackbar.show = true;
        this.snackbar.text = "Template loaded."
        this.snackbar.color = "green";
        
        let app = this;
        window.setTimeout(function(){
          app.unsavedChanges = true;
        }, 1000);
      },
      getDefaultTemplate() {
        let app = this;
        this.loading = true;

        this.axios.post(this.ApiEndpoint, {
          table: "templates",
          operation: "select",
          fields: ["id", "columns", "visual"],
          where: [{client_id:{eq:this.$store.state.user.payload.id}}, {is_default:{eq:"1"}}, {deleted:{eq:"0"}}]
        }).then(res => {
          if(res.data.data?.length > 0) {
            let cols = JSON.parse(res.data.data[0].columns);
            app.viz = JSON.parse(res.data.data[0].visual);

            app.columns = [];
            app.columns.push({formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center", headerSort:false, width:5});
            app.currentTemplateId = res.data.data[0].id;
            
            for(const col in cols) {
              for(const item in app.allColumns) {
                if(app.allColumns[item].field == cols[col]) app.columns.push(app.allColumns[item]);
              }
            }
          }
          else app.columns = app.allColumns;
        }).catch(() => {
          app.columns = app.allColumns;
        }).finally(() => {
          app.addRowCount();
          app.tabulator.setColumns(app.columns);
          app.loading = false;
        });
      },
      saveTemplate() {
        if(this.currentTemplateId>0) {
          this.loading = true;
          let app = this;
          let columns = [];
          
          let tabulatorColumns = this.tabulator.getColumns();
          for(let i=0;i < tabulatorColumns.length; i++) {
            if(tabulatorColumns[i].getField()) columns.push(tabulatorColumns[i].getField());
          }

          this.axios.post(this.ApiEndpoint, {
            table: "templates",
            operation: "update",
            data: [{"id": this.currentTemplateId, "columns": JSON.stringify(columns)}],
          }).then(() => {
            app.rerenderTemplates++;
            app.snackbar.show = true;
            app.snackbar.text = "Template saved."
            app.snackbar.color = "green";
            app.loading = false;
          }).catch(() => {
            app.snackbar.show = true;
            app.snackbar.text = "Template not saved."
            app.snackbar.color = app.$vuetify.theme.themes.light.error;
            app.loading = false;
          }).finally(() => {
            app.loading = false;
          });
        }
        else {
          this.snackbar.show = true;
          this.snackbar.text = "You first have to load a tempalte"
          this.snackbar.color = this.$vuetify.theme.themes.light.error;
        }
      },
      SaveNewTemplate() {
        this.loading = true;
        let app = this;
        let columns = [];
        
        let tabulatorColumns = this.tabulator.getColumns();
        for(let i=0;i < tabulatorColumns.length; i++) {
					if(tabulatorColumns[i].getField()) columns.push(tabulatorColumns[i].getField());
				}
        this.axios.post(this.ApiEndpoint, {
          table: "templates",
          operation: "insert",
          data: [{"columns": JSON.stringify(columns)}, {name: this.newTemplateName}, {is_default: this.templateMakeDefault}],
        }).then(() => {
          app.rerenderTemplates++;
          app.snackbar.show = true;
          app.snackbar.text = "Template saved."
          app.snackbar.color = "green";
          app.loading = false;
        }).catch(() => {
          app.snackbar.show = true;
          app.snackbar.text = "Template not saved."
          app.snackbar.color = app.$vuetify.theme.themes.light.error;
          app.loading = false;
        }).finally(() => {
          app.loading = false;
        });
      },
      getCustomers() {
        let app = this;
        this.loading = true;

        this.axios.post(this.ApiEndpoint, {
          table: "locations",
          operation: "select",
          fields: ["id", "parent_id", "name", "address", "address2", "address3", "city", "state", "zip"],
          where: [{client_id: {"eq":this.$store.state.user.payload.id}}, {deleted:{"eq":"0"}}],
          order: {"parent_id": "ASC"}
        }).then(res => {
            if(res.data?.data?.length > 0) {
              res.data.data.forEach(item => {
                if(item.parent_id == 0) {
                  app.locations.push({"id": item.id, "parent_id": item.parent_id, "text":item.name, "address": (item.address ? item.address : ""), "address2": item.address2, "address3": item.address3, "city": (item.city ? item.city : ""), "zip": (item.zip ? item.zip : "")});
                  // Look for departments
                  res.data.data.forEach(child => {
                    if(child.parent_id == item.id) {
                      app.locations.push({"id": child.id, "text": "- " + item.name + ": " + child.name, "parent_id": child.parent_id, "address": (child.address ? child.address : "") + " " + (child.city ? child.city : "") + " " + (child.zip ? child.zip : ""), });
                    }
                  });
                }
              });
            }
        }).finally(() => {
          app.loading = false;
        });
      },
      onResize() {
        this.windowHeight = window.innerHeight-200;
      }
    },
    computed: {
      showDeleteBtn() {
        if(this.tableBuilt) {
          return this.tabulator.getSelectedRows().length;
        }
        else return false;
      },
      columnFields() {
        return this.columns.filter(item => {
          return item.title != null;
        })
      },
      columnTextFields() {
        return this.columns.filter(item => {
          return item.title != null && ['venue', 'location', 'make', 'model', 'description', 'notes', 'result', 'user', 'visual'].indexOf(item.field) != -1
        })
      }
    },
    mounted() {
      this.getDefaultTemplate();
      this.getCustomers();
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
      
      // Setup tabulator events
      this.tabulator = this.$refs.tabulator.getInstance();
      this.tabulator.on('tableBuilt', this.initialized);
      this.tabulator.on('groupClick', this.groupClick);
      this.tabulator.on('columnMoved', this.columnMoved);
      this.tabulator.on('cellEdited', this.cellEdited);
    },
    watch: {
      'report.location'() {
        this.unsavedChanges = true;
      },
      'report.reminders'() {
        this.unsavedChanges = true;
      },
      'report.validTo'() {
        this.unsavedChanges = true;
      },
      'report.nextTestBooked'() {
        this.unsavedChanges = true;
      },
      'report.notes'() {
        this.unsavedChanges = true;
      }
    }
  }
</script>
<style>
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background: #256aa5;
}
body::-webkit-scrollbar {
    width: 0;
}
body {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.rotate {
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(18deg);
}
.tabulator {
  font-size: 14px;
}
.tabulator-container {
  /*height: 575px;*/
  margin: 0;
  padding: 0;
}
.tabulator .tabulator-header .tabulator-col {
  background: #256aa5;
  background: linear-gradient(0deg, rgba(10,91,131,1) 0%, rgba(37,106,165,1) 25%, rgba(0,166,255,1) 100%);
  color: #fff;
  font-size: 12px;
}
.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
  background-color:rgb(56, 56, 56);
  color: black;
}
.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  padding:5px;
}
.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter {
    right: -5px;
}
.v-icon-delete {
  color: #fff;
}
.tabulator .tabulator-header .tabulator-col .tabulator-header-filter input {
  border: none;
  border-radius: 0;
  font-size: 10px;
  line-height: initial;
  font-weight: 400;
  background: #fff;
}
.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row {
  font-weight: 700;
  font-size: smaller;
  
}
.tabulator .tabulator-tableHolder .tabulator-table .tabulator-row.tabulator-calcs {
  font-weight: normal;
  font-size: smaller;
}
.tabulator-row.tabulator-selected {
  background-color: #98d4f6;
}
.tabulator-row.tabulator-selected:hover {
  background-color: #488faf;
}
.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.tabulator-calcs {
  font-size: smaller;
}
.tabulator .tabulator-footer .tabulator-calcs-holder {
  border-bottom: none;
}
.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort=ascending] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
    border-top: none;
    border-bottom: 6px solid #0bb0ff;
}
.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort=descending] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
    border-bottom: none;
    border-top: 6px solid #0bb0ff;
    color: #0bb0ff;
}
.machine {
  background:#eeeeee;
  font-size: smaller;
  font-weight: 400;
}
.v-input--switch {
  margin-top: 0;
  margin-left: 16px;
}
.bg-gradient {
  background: linear-gradient(0deg, rgba(10,91,131,1) 0%, rgba(37,106,165,1) 25%, rgba(0,166,255,1) 100%);
}
.success-gradient {
  background: linear-gradient(0deg, #419244 0%, #4caf50 25%, #60dc64 100%);
}
</style>
